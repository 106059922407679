import React from 'react';
import LogoSVG from '../svg/logo.svg';

const Logo = () => {
  return (
    <section className="Logo">
      <LogoSVG />
    </section>
  );
};

export default Logo;
