import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setShow } from '../actions/animActions';
import { setTriggerVisible } from '../actions/navActions';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

import Layout from '../components/Layout';
import Logo from '../components/Logo';
import SEO from '../components/Seo';

class IndexPage extends Component {
  componentDidMount() {
    this.props.setShow(false);
    this.props.setTriggerVisible(true);
  }

  render() {
    return (
      <Layout>
        <SEO title="Home" description="Marougrav est une entreprise de gravure située en Brabant Wallon. Nous réalisons tous vos projets de gravure mécanique, gravure laser, impression et nous fournissons aussi les consommables." keywords={['gravure']} />
        <Logo />
        <section className="Home">
          <article
            className={classNames('Home__service', this.props.current === 1 ? 'active' : '')}
            style={
              {
                backgroundImage: `
                linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .6) 50%, rgba(0, 0, 0, 1) 100%),
                url(${this.props.data.meca.childImageSharp.fluid.src})
                `,
              }
            }
          >
            <AniLink cover to="/gravure-mecanique" bg="#007A9B" className="Home__content">
              <span>Découvrir</span>
              <h2>Gravure Mécanique</h2>
            </AniLink>
          </article>
          <article
            className={classNames('Home__service', this.props.current === 2 ? 'active' : '')}
            style={
              {
                backgroundImage: `
                linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .6) 50%, rgba(0, 0, 0, 1) 100%),
                url(${this.props.data.laser.childImageSharp.fluid.src})
                `,
              }
            }
          >
            <AniLink cover to="/marquage-laser" bg="#007A9B" className="Home__content">
              <span>Découvrir</span>
              <h2>Marquage Laser</h2>
            </AniLink>
          </article>
          <article
            className={classNames('Home__service', this.props.current === 3 ? 'active' : '')}
            style={
              {
                backgroundImage: `
                linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .6) 50%, rgba(0, 0, 0, 1) 100%),
                url(${this.props.data.print.childImageSharp.fluid.src})
                `,
              }
            }
          >
            <AniLink cover to="/impression" bg="#007A9B" className="Home__content">
              <span>Découvrir</span>
              <h2>Impression et<br />découpe vinyle</h2>
            </AniLink>
          </article>
          <article
            className={classNames('Home__service', this.props.current === 4 ? 'active' : '')}
            style={
              {
                backgroundImage: `
                linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, .6) 50%, rgba(0, 0, 0, 1) 100%),
                url(${this.props.data.screw.childImageSharp.fluid.src})
                `,
              }
            }
          >
            <AniLink cover to="/consommables" bg="#007A9B" className="Home__content">
              <span>Découvrir</span>
              <h2>Consommables</h2>
            </AniLink>
          </article>
        </section>
        <section className="Home__banner">
          <div className="row">
            <div className="col-md-6 Home__banner_left">
              <h2>Gravure en Brabant Wallon</h2>
            </div>
            <div className="col-md-6 Home__banner_right">
              <span>Un devis, une question ?</span>
              <AniLink className="btn btn-white" cover to="/contact" bg="#F67280">Contactez-nous !</AniLink>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    meca: file(relativePath: { eq: "index/meca.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    laser: file(relativePath: { eq: "index/laser.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    print: file(relativePath: { eq: "index/print.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    screw: file(relativePath: { eq: "index/screw.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  setShow: PropTypes.func.isRequired,
  setTriggerVisible: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setShow,
  setTriggerVisible,
};

export default connect(null, mapDispatchToProps)(IndexPage);
